import queryClient from '@api/queryClient'
import Toaster from '@components/Toaster'
import { classnames } from '@tools/common'
import { Analytics } from '@vercel/analytics/react'
import { appWithTranslation } from 'next-i18next'
import type { AppProps } from 'next/app'
import { Montserrat, Roboto } from 'next/font/google'
import { QueryClientProvider } from 'react-query'
import '../styles/global.css'

const roboto = Roboto({
  subsets: ['latin'],
  style: 'normal',
  weight: ['400', '500', '700'],
  variable: '--font-roboto',
})

const montserrat = Montserrat({
  subsets: ['latin'],
  style: 'normal',
  weight: ['700'],
  variable: '--font-montserrat',
})

const MyApp = ({ Component, pageProps }: AppProps) => {
  return (
    <QueryClientProvider client={queryClient}>
      <div
        className={classnames(
          'relative z-0 font-roboto',
          montserrat.variable,
          roboto.variable,
        )}
      >
        <Component {...pageProps} />
      </div>

      <Toaster />
      <Analytics />
    </QueryClientProvider>
  )
}

export default appWithTranslation(MyApp)
