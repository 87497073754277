import httpClient from '@api/httpClient'
import type { AuthData, User } from '@models/user'
import type { AxiosAuthRefreshRequestConfig } from 'axios-auth-refresh'
import { authUrls } from './constants'
import { clearAuthCredentials, setAuthCredentials } from './tools'

const getUser = async () => {
  const { data } = await httpClient.get<User>(authUrls.me)
  return data
}

const login = async ({
  login,
  password,
}: {
  login: string
  password: string
}) => {
  const { data } = await httpClient.post<AuthData>(authUrls.login, {
    login,
    password,
  })
  setAuthCredentials(data)
}

const logout = async () => {
  clearAuthCredentials()
}

const refreshToken = async (refreshToken: string) => {
  const { data } = await httpClient.post<AuthData>(
    authUrls.refresh,
    {
      refreshToken,
    },
    { skipAuthRefresh: true } as AxiosAuthRefreshRequestConfig,
  )

  setAuthCredentials(data)

  return data
}

export default { login, logout, refreshToken, getUser }
